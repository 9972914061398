.category-container {
  display: flex !important;
  justify-content: center !important;
  padding: .5em !important;
}
.banner {
  margin-bottom: 3em !important;
}

.banner_category a {
  font-size: 15px !important;
}

.container {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  .category-container {
    display: flex !important;
    justify-content: center !important;
    padding: .5em !important;
    margin: .1em
  }
  .banner_category a {
    font-size: 13px !important;
    margin-top: -2em !important ;
  }
}