.parent {
  display: flex;
  justify-content: center !important;
}
.carousel-container {
  width: 100%;
}

.slider {
  margin: 0 1em;
  overflow: 'hidden';
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.slider-active {
  border: 1px solid #191364 !important;
  border-radius: 20px !important;
}

.slider.selected {
  background-color: lightcoral !important;
}

.slider img {
  width: 5em !important;
  height: 5em !important;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .embla {
    width: 20em !important;
  }
  .col-sm-6 {
    width: 50% !important;
  }
  .product_name {
    font-size: 0.6em;
    height: 4em !important;
  }
  .img-fluid {
    height: 50% !important;
  }
  .product_image {
    height: 50% !important;
  }
  .product_image img {
    height: 5em !important;
  }
  .product_price.mt-n3 {
    margin-top: 2em !important;
  }
  .plue,
  .minus {
    padding-left: 11px !important;
    padding-right: 9px !important;
  }
  .quantity_selector {
    margin-right: 2em;
    width: 50% !important;
  }
}
