@media only screen and (min-width: 600px) {
  .btn-arrow-right button {
    margin-top: 10em !important;
  }
}

@media only screen and (max-width: 600px) {
  footer {
    margin-top: 15em !important;
  }
  .prod-info {
    height: 12em;
  }
  .row-arrow {
    display: flex !important;
    justify-content: space-around !important;
    top: -3em;
  }
  .single_product_image_background {
    height: 75% !important;
  }
  .row-info {
    position: relative;
    top: -15em !important;
  }
  .btn-arrow-left {
    position: absolute;
    top: 9em;
    left: 0.5em;
    z-index: 2;
  }
  .description {
    margin-top: 1em !important;
  }
  .btn-arrow-right {
    position: absolute;
    bottom: 21.3em;
    z-index: 2;
    left: 19em !important;
  }

  .btn-arrow-right button {
    margin-top: -3em !important;
  }
  .product-add_to_cart_button {
    margin-top: 1em;
  }
  .quantity {
    margin-top: 2em !important;
  }
  .quantity_selector.quantity_selector1 {
    width: 50% !important;
    position: relative;
    right: 25% !important;
    margin-left: 0 !important;
  }
}
